import React from 'react';
import Fullpage from './Fullpage';
import './App.scss';

function App() {
  return (
    <Fullpage />
  );
}

export default App;
