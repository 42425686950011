import React from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import galleryImages from "./galleryImages.json"

const GalleryPage = () => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="gallery">
        <div className="masonry-wrapper">
          <div className="masonry">
            <Gallery photos={galleryImages.map(image => {
              return {
                ...image,
                src: `/assets/img/photos/thumbs/${image.fileName}`
              }
            })} targetRowHeight={200} onClick={openLightbox} />
          </div>
        </div>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={galleryImages.map(image => {
                return {
                  ...image,
                  src: `/assets/img/photos/${image.fileName}`
                }
              })}
              styles={{
                view: base => ({
                  ...base,
                  '& > img': {
                    maxHeight: '100vh',
                  },
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  )
}

export default GalleryPage;
