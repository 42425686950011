import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import GalleryPage from './GalleryPage';
import { ReactComponent as ArrowDown } from './assets/arrow_down.svg'

const Fullpage = () => {

  return (
    <ReactFullpage
      //fullpage options
      licenseKey="3C4522F4-02FF4FB1-857BCFF9-7C9F797C"
      scrollingSpeed = {1000}
      normalScrollElements={'.masonry'}
      sectionsColor= {[]} /* <---- here */

      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section section-home fp-section fp-table fp-completely">
              <div className="menu-spacer"></div>
              <div className="content-wrapper">
                  <div className="content home">
                      <h1 className="title">David + Emily</h1>
                      <h2 className="date">3 May 2018</h2>
                  </div>
                  <a className="arrow" href onClick={() => fullpageApi.moveSectionDown()}><ArrowDown /></a>
              </div>
            </div>
            <div className="section section-photos fp-section fp-table bg-navy">
              <GalleryPage />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default Fullpage;
